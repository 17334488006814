<template>
  <div
    v-if="getDescriptionWallet"
    class="d-inline"
  >
    <template v-for="(part, index) in splitDescription">
      <span :key="`a-${index}`">
        {{ part }}
      </span>
      <a
        v-if="index !== splitDescription.length - 1"
        :key="index"
        :href="getWalletUrl(getDescriptionWallet(index))"
        target="_blank"
      >
        {{ getDescriptionWallet(index) }}
      </a>
    </template>
  </div>
  <span v-else>
    {{ descriptionText }}
  </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { BC } from '@securitize/domain';

export default {
  name: 'Description',
  props: {
    descriptionText: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('configToken', ['deploymentToken']),
    ...mapGetters('configToken', ['getAvailableNetworks']),
    ...mapGetters('issuersInfo', ['issuerInfo']),
    isAlgorandNetwork() {
      return this.deploymentToken.provider && this.deploymentToken.provider.includes('algorand');
    },
    regex() {
      switch (this.isAlgorandNetwork) {
        case true:
          return new RegExp('[0-9A-Z]{58}', 'g');
        default:
          return new RegExp('(?:(?:0x)[a-fA-F0-9]{40})', 'g');
      }
    },
    splitDescription() {
      return this.descriptionText.split(this.regex);
    },
  },
  methods: {
    getWalletUrl(walletId) {
      let walletExplorerUrl = null;
      try {
        walletExplorerUrl = BC.getExplorerUrl({
          blockchainNetwork: this.deploymentToken.provider,
          operation: BC.OperationTypes.WALLET_ADDRESS,
          value: walletId,
          environment: process.env.VUE_APP_ENVIRONMENT_NAME,
        });
      } catch (error) {
        console.error('Error getting wallet explorer url', error);
      }

      return walletExplorerUrl || '';
    },
    getDescriptionWallet(index = 0) {
      const text = this.descriptionText || '';
      const addressText = text.match(this.regex) || [];
      return addressText[index];
    },
  },
};
</script>
